<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CButton @click="collapse=!collapse" color="primary" class="mb-2 mr-2">
                    Filter
                </CButton>
                <CCollapse :show="collapse" :duration="400">
                    <CCard>
                        <CCardHeader>
                            <strong>Filter Options</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.id.val" :description="form.id.inv" label="ID" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.source_url.val" :description="form.source_url.inv" label="Source URL" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.data_type.val"
                                        :description="form.data_type.inv"
                                        label="Data Type"
                                        :options="[
                                            {value: '1', label:'داده از نوع API JSON ماننده وب‌سایت tgju'},
                                            {value: '2', label:'داده از نوع وب‌سایت tsetmc'},
                                            {value: '3', label:'داده از نوع وب‌سایت netdania'},
                                            {value: '4', label:'داده از نوع وب‌سایت معمولی و پردازش HTML'},
                                            {value: '5', label:'استخراج داده در میان عبارات مشخص'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.data_type.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <label>Last Check</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.last_check.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.last_check.inv}}</small>
                                </CCol>
                            </CRow>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="validator();filter=true;page=1;count();get();" color="primary" class="mr-2">Filter</CButton>
                                <CButton @click="remove_filter" color="danger">Remove Filter</CButton>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCollapse>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Data Sources</strong>
                        <div class="card-header-actions">
                            Number of All Rows: {{row_count}}
                        </div>
                        </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }">
                            <template #is_active="data">
                                <td>
                                    <CBadge :color="get_badge(data.item.is_active)">
                                        {{data.item.is_active}}
                                    </CBadge>
                                </td>
                            </template>
                            <template #current_state="data">
                                <td>
                                    <CBadge :color="get_badge(data.item.current_state)">
                                        {{data.item.current_state}}
                                    </CBadge>
                                </td>
                            </template>
                            <template #data_type="data">
                                <td>
                                    <CBadge :color="get_badge(data.item.data_type)">
                                        {{data.item.data_type}}
                                    </CBadge>
                                </td>
                            </template>
                            <template #action="data">
                                <td>
                                    <template>
                                    <div class="flex-container">
                                        <div @click="redirect_to(`/data/source/${data.item.id}/details`)"
                                            v-c-tooltip.hover="'Details'">
                                        <CIcon height="20" :content="$options.details"/>
                                        </div>
                                        <div @click="redirect_to(`/data/source/${data.item.id}/last`)"
                                            v-c-tooltip.hover="'Last Data'">
                                        <CIcon height="20" :content="$options.last_data"/>
                                        </div>
                                        <div v-show="$store.state.perm['arbitrage.DataSources'].put"
                                            @click="redirect_to(`/data/source/${data.item.id}/update`)"
                                            v-c-tooltip.hover="'Update'">
                                        <CIcon height="20" :content="$options.update"/>
                                        </div>
                                        <div v-show="$store.state.perm['arbitrage.DataSources'].delete"
                                            @click="delete_modal=true;id=data.item.id"
                                            v-c-tooltip.hover="'Delete'">
                                        <CIcon height="20" :content="$options.delete"/>
                                        </div>
                                    </div>
                                    </template>
                                </td>
                            </template>
                        </CDataTable>
                            <br>
                            <div> 
                                <CPagination
                                :activePage.sync="page"
                                :pages="page_count"
                                align="center"
                                :doubleArrows="true"
                                />
                            </div>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Alert"
            size="sm"
            color="danger"
            :show.sync="delete_modal"
            >
            <p>Are you sure?</p>
            <template #footer>
                <CButton @click="delete_modal=false" color="secondary">No</CButton>
                <CButton @click="delete_row" color="danger">Yes</CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import { cilListRich, cilPencil, cilTrash, cilDataTransferDown } from '@coreui/icons'
export default {
    details: cilListRich, update: cilPencil, delete: cilTrash, last_data: cilDataTransferDown,
    data () {
        return {
            row_count: '',
            items: [],
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'source_url', label: 'Source URL' },
                { key: 'data_type', label: 'Data Type' },
                { key: 'is_active', label: 'State' },
                { key: 'current_state', label: 'Current State' },
                { key: 'last_check', label: 'Last Check' },
                { key: 'action', label: 'Action' }
            ],
            collapse: false,
            filter: false,
            form: {
                id: {val: '', inv: '', v: { max: 1024 }},
                name: {val: '', inv: '', v: { max: 1024 }},
                source_url: {val: '', inv: '', v: { max: 2048 }},
                is_active: {val: '', inv: '', v: { max: 1024 }},
                data_type: {val: '', inv: '', v: { max: 1024 }},
                last_check: {val: '', inv: '', v: { max: 1024 }},
            },
            disable: false,
            page: 1,
            page_count: 0,
            offset: 0,
            delete_modal: false,
            id: 0,
        }
    },
    watch: {
        page: {
            handler(){
                this.count();
                this.get();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    },
    mounted(){
        this.count();
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        count() {
            if(this.disable == false) {
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/data_sources/?count=1' + filter)
                    .then(res => {
                        this.row_count = String(res.data.count).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        if(res.data.count > 100){
                            this.page_count = Math.ceil(res.data.count / 100);
                        } else {
                            this.page_count = 1;
                        }
                    })
            }
        },
        get() {
            if (this.disable == false) {
                this.offset = 0;
                if(this.page != 1){
                    this.offset = (this.page * 100) - 99;
                }
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/data_sources/' 
                    + '?limit=100' 
                    + '&offset=' + this.offset
                    + '&omit_fields=last_data'
                    + filter)
                    .then(res => {
                        res.data.map(val => {
                            if(val.last_check != '0001-01-01T00:00:00Z'){
                                val.last_check = this.$moment(val.last_check).format('YYYY-MM-DD HH:mm:ss');
                            }
                            switch(val.is_active){
                                case true:
                                    val.is_active = 'Active';
                                    break;
                                case false:
                                    val.is_active = 'Inactive';
                                    break;
                            }
                            switch(val.current_state){
                                case true:
                                    val.current_state = 'Active';
                                    break;
                                case false:
                                    val.current_state = 'Inactive';
                                    break;
                            }
                            switch(val.data_type){
                                case 1:
                                    val.data_type = 'API JSON - tgju.org';
                                    break;
                                case 2:
                                    val.data_type = 'tsetmc.com';
                                    break;
                                case 3:
                                    val.data_type = 'netdania.com';
                                    break;
                                case 4:
                                    val.data_type = 'HTML Processing';
                                    break;
                                case 5:
                                    val.data_type = 'Data Mining among Specific Expressions';
                                    break;
                                case 6:
                                    val.data_type = 'Data Mining from Telegram Channel';
                                    break;
                                case 7:
                                    val.data_type = 'Data Mining among Specific Expressions (Advanced)';
                            }
                        });
                        this.items = res.data;
                    })
            }
        },
        delete_row() {
			this.axios.delete('arbitrage/data_sources/'
			+ '?id=' + this.id)
			.then(() => {
				this.$notify({
					group: 'foo',
					type: 'success',
					text: 'Deleted Successfully.'
                });
				this.delete_modal = false;
                this.count();
				this.get();
			})
			.catch(() => {
				this.$notify({
					group: 'foo',
					type: 'error',
					text: 'Error! please try again.'
                });
                this.delete_modal = false;
			})
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        get_badge(status) {
            switch (status) {
                case 'Active': return 'success'
                case 'Inactive': return 'danger'
                default: return 'secondary'
            }
        },
        remove_filter() {
            for (const key in this.form) {
                this.form[key].val = '';
                this.form[key].inv = '';
            }
            this.disable = false;
            this.filter = false;
            this.page = 1;
            this.count();
            this.get();
        },
    },
}
</script>
